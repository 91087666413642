nav {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: transparent;
    box-shadow: 0px 0px 10px transparent;
    padding: 30px 0;
    position: fixed;
    transition: 0.2s;
    z-index: 10;
}

.navbar-bg {
    background-color: var(--base-100);
    box-shadow: 0px 0px 10px var(--base-100);
    padding: 20px 0;
}

.navbar {
    width: 100%;
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    opacity: 0;
    animation: navbar 1.5s forwards;
    position: relative;
}


@media only screen and (max-width: 768px) {
    nav {
        padding: 15px 0;
        opacity: 1;
        animation: none;
    }
    
}

@keyframes navbar {
    from {
        top: 20px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}