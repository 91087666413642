.logo-img {
    aspect-ratio: 1;
    width: 50px;
    border-radius: 50%;
    box-shadow: 0px 0px 5px #30a26160;
    transition: 0.2s;
}

.logo-img:hover {
    border-radius: 10%;
}