.header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: var(--base-100);
    background-image: url(../../../assets/images/bg-header.jpg);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    opacity: 0;
    animation: fadeIn 1s forwards;
}

.header-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.header-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    animation: fadeIn 2s forwards;
    gap: 5px;
    z-index: 10;
    flex: 1;
}

.header-text .title {
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 15px;
    color: var(--primary);
}

.header-text .download {
    display: flex;
    gap: 15px;
    margin-top: 30px;
}

.header-text .download img {
    width: 140px;
    height: auto;
    transition: 0.2s;
    border-radius: 5px;
}

.header-text .download img:hover {
    box-shadow: 0px 0px 10px #30a26160;
}

.mockups {
    flex: 1;
    display: inline-block;
    white-space: nowrap;

}

.mockup_1-img {
    width: calc(360px - 10em);
    max-width: 45vw;
    animation: mockup_1 1s forwards;
    filter: drop-shadow(-5px 0px 10px #30a26160);
    z-index: 2;
}
.mockup_2-img {
    width: calc(320px - 10em);
    max-width: 45vw;
    position: relative;
    left: -20px;
    animation: mockup_2 1s forwards;
    z-index: 1;
    filter: drop-shadow(-5px 0px 10px #30a26160);
}

@media only screen and (max-width: 768px) {
    .header-content {
        flex-direction: column;
        align-items: center;
    }
    .mockup_1-img {
        width: calc(300px - 10em);
    }
    .mockup_2-img {
        width: calc(280px - 10em);
    }
    .mockups {
        text-align: center;
    }
    .header-text {
        align-items: center;
        text-align: center;
    }
}

@keyframes mockup_1 {
    from {
        transform: translate(0, 100px);
        opacity: 0;
    }
    to {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes mockup_2 {
    from {
        transform: translate(0, -100px);
        opacity: 0;
    }
    to {
        transform: translate(0, -20px);
        opacity: 1;
    }
}