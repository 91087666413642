:root {
  --primary: #31A362;
  --secondary: #D58936;
  --accent: #F2F3AE;
  --text: #F9FAFB;
  --text-body: #9D9D9D;
  --base-100: #0F1110;
  --base-200: #474A48;
  --base-300: #1F2120;
  --success: #5CBD27;
  --warning: #EAC652;
  --danger: #EA5252;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
