.deleteAccount {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    background-image: url(../../../assets/images/bg-header.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 90px 0;
    box-sizing: border-box;
}

.deleteAccount > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    background-color: var(--base-300);
    padding: 60px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0px 0px 10px var(--base-100);
    animation: fadeIn 1s forwards;
}

.deleteAccount .paragraph {
    margin-bottom: 15px;
}

.border-bottom {
    border-bottom: 1px solid var(--primary);
}

.list {
    list-style: revert;
    margin-bottom: 15px;
}

.list li {
    margin-left: 25px;
    margin-top: 5px;
}

.button {
    display: inline-block;
    background-color: var(--primary);
    color: var(--text);
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
    filter: brightness(1);
}

.deleteAccount a {
    transition: 0.2s;
    filter: brightness(1);
}

.button:hover, .deleteAccount a:hover {
    filter: brightness(1.2);
}

@media screen and (max-width: 768px) {
    .deleteAccount {
        padding-top: 90px;
        padding-bottom: 15px;
    }
}