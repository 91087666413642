@font-face {
  font-family: "radio_canada";   /*Can be any text*/
  src: local("radio_canada"),
      url("../src/app/shared/assets/fonts/radio_canada/RadioCanada-VariableFont_wdth,wght.ttf") format("truetype");
}

.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

* {
  scroll-behavior: smooth;
}

body {
  background-color: var(--base-100);
  color: var(--text);
  font-family: radio_canada;
  letter-spacing: .5px;
  transition: none;
}
#root {
  overflow-x: hidden;
  transition: 0.2s;
}

.bg-secondary {
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgb(0,27,82);
  background: -moz-radial-gradient(circle, rgba(0,27,82,1) 0%, rgba(15,23,36,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(0,27,82,1) 0%, rgba(15,23,36,1) 100%);
  background: radial-gradient(circle, rgba(0,27,82,1) 0%, rgba(15,23,36,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#001b52",endColorstr="#0f1724",GradientType=1); 
  position: relative;
}

.section.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  padding: 60px 0;
}

.bold {
  font-weight: bold;
}

.border-box {
  box-sizing: border-box;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.font-md {
  font-size: 1.8em;
}
.font-lg {
  font-size: 2.2em;
}
.font-xl {
  font-size: 3em;
}

.text-secondary {
  color: var(--primary);
}

.app {
  display: flex;
  justify-content: center;
}

.fixed {
  position: fixed;
}
.w-100  {
  width: 100%;
}

.section-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 60px 0;
  font-size: 2em;
}

.ov-hidden {
  overflow: hidden;
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.container {
  width: 100%;
  max-width: min(90%, 1300px);
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .container {
      width: calc(100vw - 30px);
  }
  .hide-mobile {
      display: none;
  }
  .section-title {
      font-size: 1.6em;
  }
}


/* ---- CSS RESET ---- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}